import React from 'react'
import { Box, Flex } from 'reflexbox'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { useIntl } from 'react-intl'
import Button from 'src/components/UI/Button/Button'
import { Container } from 'src/components/UI/Grid/Grid'
import * as styles from './404.module.scss'
const NotFound = () => {
  const { formatMessage } = useIntl()
  return (
    <Container>
      <Flex
        className={styles.wrapper}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          marginBottom={8}
          as="h1"
          width={[1, null, null, 4 / 12]}
          className={styles.title}
        >
          {formatMessage({ id: 'notFoundMessage' })}
        </Box>
        <Button to={'/'}>{formatMessage({ id: 'notFoundButton' })}</Button>
      </Flex>
    </Container>
  )
}

export default NotFound
